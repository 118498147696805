import axios from 'axios';


const API_BASE_URL = 'https://admin.api.yukthi.net';


const login = async (username, password) => {
    const url = `${API_BASE_URL}/user/login`;
    try {
        const response = await axios.post(url, {}, {
            headers: {
                'accept': 'application/json',
                'Authorization': `Basic ${btoa(`${username}:${password}`)}`
            }
        }, { withCredentials: true });
        localStorage.setItem('user_email', username);
        localStorage.setItem('Session-ID', response.data['Session-ID']);
        return response.data;
    } catch (error) {
        console.error('Login API call failed:', error.response.data);
        throw new Error(error.response.data.message);
    }
};


const getUserDetails = async () => {
    const url = `${API_BASE_URL}/user/info`;
    try {
        const response = await axios.get(url, {
            headers: {
                'accept': 'application/json',
                'Session-ID': localStorage.getItem('Session-ID')
            }
        });
        return response.data;
    } catch (error) {
        console.error('Get User Details API call failed:', error.response.data);
        throw new Error(`Get User Details failed: ${JSON.stringify(error.response)}`);
    }
};


export { API_BASE_URL, login, getUserDetails };
