import React, { useState } from 'react';
import { Container, Typography, TextField, Button, CircularProgress } from '@mui/material';
import { login } from '../api/BaseCalls';

const LoginPage = () => {
    const [loading, setLoading] = useState(false);

    if (localStorage.getItem('Session-ID')) {
        window.location.href = '/logs/email-flow';
    }

    const handleLogin = async (user_id, password) => {
        try {
            setLoading(true);
            const expiryTime = new Date().getTime() + (3 * 60 * 60 * 1000) - (5 * 60 * 1000);   // Expiry time is set to 3 hour from now with 5 minutes buffer
            await login(user_id, password);
            alert(`Welcome ${user_id}!`);

            localStorage.setItem('expiry_time', expiryTime);
            window.location.href = '/logs/email-flow';
        } catch (error) {
            alert(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const user_id = formData.get('user_id');
        const password = formData.get('password');
        await handleLogin(user_id, password);
    };

    const contStyle = {
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 4,
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    };

    const h4Style = {
        marginBottom: 2,
        color: '#333',
        fontFamily: 'Arial, sans-serif',
    };

    const textFieldStyle = {
        marginBottom: 2,
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#ccc',
            },
            '&:hover fieldset': {
                borderColor: '#888',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#3f51b5',
            },
        },
    };

    return (
        <Container maxWidth="sm" sx={contStyle}>
            <Typography variant="h4" gutterBottom sx={h4Style}>Login</Typography>
            <form onSubmit={handleSubmit} style={{ width: '100%', marginTop: '8px' }}>
                <TextField
                    name='user_id'
                    type="text"
                    label="User ID"
                    variant="outlined"
                    fullWidth
                    autoComplete='username'
                    required
                    sx={textFieldStyle}
                />
                <TextField
                    name='password'
                    type="password"
                    label="Password"
                    variant="outlined"
                    fullWidth
                    autoComplete='current-password'
                    required
                    sx={textFieldStyle}
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={loading}
                    sx={{
                        marginTop: 3,
                        backgroundColor: '#3f51b5',
                        color: '#fff',
                        '&:hover': {
                            backgroundColor: '#303f9f',
                        },
                    }}
                >
                    {loading ? <CircularProgress size={24} sx={{ color: '#fff' }} /> : 'Log in'}
                </Button>
            </form>
        </Container>
    );
};

export default LoginPage;
