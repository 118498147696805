import axios from "axios";

const API_BASE_URL = 'https://admin.api.yukthi.net';


export const fetchEmailLogs = async (page, per_page, filterData) => {
    try {
        const url = `${API_BASE_URL}/logs/admin`;

        const headers = {
            'accept': 'application/json',
            'Session-ID': localStorage.getItem('Session-ID')
        };

        const params = {
            "current_page": page,
            "page_size": per_page
        }
        
        const response = await axios.post(url, filterData, {params, headers});
        return response.data;
    } catch (error) {
        console.error('Error fetching email logs', error);
        return { data: [], pagination: { page: 1, per_page: 10, total: 0 } };
    }
};
