import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/navigation/Navbar';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import { Box } from '@mui/system';
import NotFound from './pages/NotFound';
// import Dashboard from './pages/Dashboard';
import AboutPage from './pages/AboutPage';
import MailFlowLogs from './pages/MailFlowLogs';
import './App.css';

const App = () => {
    return (
        <Router>
            <Navbar />
            <Box mt={10} />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/login" element={<LoginPage />} />
                {/* <Route path="/dashboard" element={<Dashboard />} /> */}
                <Route path="/about" element={<AboutPage />} />
                <Route path="/logs/email-flow" element={<MailFlowLogs />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Router>
    );
};

export default App;
