import React, { useState } from 'react';
import { Box, TextField, Button, Grid, Typography } from '@mui/material';

const SearchForm = ({ onSearchChange }) => {
    const [search, setSearch] = useState('');
    const [euid, setEuid] = useState('');
    const [status, setStatus] = useState('');
    const [from_email_id, setFromEmailId] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [recipients, setRecipients] = useState('');

    const handleSearchChange = () => {
        const searchData = {
            search_query: search,
            filters: {
                euid: euid || undefined,
                from_email_id: from_email_id || undefined,
                status: status || undefined,
                recipients: recipients || undefined,
            },
            date_range: {
                from_date: fromDate || undefined,
                to_date: toDate || undefined,
            },
        };

        // Clean up empty fields
        if (!searchData.filters.euid) delete searchData.filters.euid;
        if (!searchData.filters.from_email_id) delete searchData.filters.from_email_id;
        if (!searchData.filters.status) delete searchData.filters.status;
        if (!searchData.filters.recipients) delete searchData.filters.recipients;
        if (!searchData.date_range.from_date) delete searchData.date_range.from_date;
        if (!searchData.date_range.to_date) delete searchData.date_range.to_date;
        // Clean up empty objects
        if (!searchData.filters || Object.keys(searchData.filters).length === 0) delete searchData.filters;
        if (!searchData.search_query) delete searchData.search_query;
        if (!searchData.date_range || Object.keys(searchData.date_range).length === 0) delete searchData.date_range;

        console.log('Search Data:', searchData);

        onSearchChange(searchData);
    };

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Box
                sx={{
                    width: '90%',
                    maxWidth: 600,
                    padding: 3,
                    border: '1px solid #ccc',
                    borderRadius: 2,
                    boxShadow: 3,
                }}
            >
                <Typography variant="h6" gutterBottom>
                    Search Logs by Filters
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="Search [ Subject, Message ID, Status ]"
                            variant="outlined"
                            fullWidth
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            sx={{ marginBottom: 2 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="From Email ID"
                            variant="outlined"
                            type="email"
                            fullWidth
                            value={from_email_id}
                            onChange={(e) => setFromEmailId(e.target.value)}
                            sx={{ marginBottom: 2 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Status"
                            variant="outlined"
                            fullWidth
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                            sx={{ marginBottom: 2 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Recipients (To, CC, BCC etc.)"
                            variant="outlined"
                            type="email"
                            fullWidth
                            value={recipients}
                            onChange={(e) => setRecipients(e.target.value)}
                            sx={{ marginBottom: 2 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="EUID"
                            variant="outlined"
                            fullWidth
                            value={euid}
                            onChange={(e) => setEuid(e.target.value)}
                            sx={{ marginBottom: 2 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Grid container justifyContent="center" alignItems="center">
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                <Typography variant="body2" sx={{ marginBottom: 1 }}>From Date</Typography>
                                <TextField
                                    variant="outlined"
                                    type="datetime-local"
                                    fullWidth
                                    value={fromDate}
                                    onChange={(e) => setFromDate(e.target.value)}
                                    sx={{ marginRight: 1, marginBottom: 2 }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                <Typography variant="body2" sx={{ marginBottom: 1, ml: 1 }}>To Date</Typography>
                                <TextField
                                    variant="outlined"
                                    type="datetime-local"
                                    fullWidth
                                    value={toDate}
                                    onChange={(e) => setToDate(e.target.value)}
                                    sx={{ marginLeft: 1, marginBottom: 2 }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" fullWidth onClick={handleSearchChange}>
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    );
};

export default SearchForm;
