import { Paper, Typography, Box } from '@mui/material';
import React from 'react';

const AboutPage = () => {
    return (
        <Paper
            sx={{
                padding: 3,
                maxWidth: 600,
                margin: 'auto',
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                borderRadius: '16px',
                background: 'linear-gradient(135deg, #f0f0f0, #ffffff)',
                transition: 'transform 0.4s, box-shadow 0.4s, background 0.4s',
                '&:hover': {
                    transform: 'scale(1.03)',
                    boxShadow: '0px 10px 35px rgba(0, 0, 0, 0.2)',
                    background: 'linear-gradient(135deg, #e3f2fd, #ffffff)',
                },
                animation: 'fadeIn 1.5s ease-in-out',
                '@keyframes fadeIn': {
                    '0%': { opacity: 0 },
                    '100%': { opacity: 1 }
                }
            }}
        >
            <Typography
                variant="h4"
                align="center"
                gutterBottom
                sx={{
                    fontWeight: 'bold',
                    color: 'primary.main',
                    animation: 'slideIn 1s ease-out',
                    transition: 'color 0.4s, transform 0.4s',
                    background: 'linear-gradient(90deg, #2196f3, #21cbf3)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    '&:hover': {
                        color: 'secondary.main',
                        transform: 'scale(1.07)',
                        background: 'linear-gradient(90deg, #ff4081, #ff79b0)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                    },
                    '@keyframes slideIn': {
                        '0%': { transform: 'translateY(-20px)', opacity: 0 },
                        '100%': { transform: 'translateY(0)', opacity: 1 }
                    }
                }}
            >
                About
            </Typography>
            <Box
                textAlign="center"
                sx={{
                    animation: 'fadeUp 1.2s ease-in-out',
                    '@keyframes fadeUp': {
                        '0%': { transform: 'translateY(20px)', opacity: 0 },
                        '100%': { transform: 'translateY(0)', opacity: 1 }
                    }
                }}
            >
                <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                        transition: 'color 0.4s, transform 0.4s',
                        '&:hover': {
                            color: '#1976d2',
                            transform: 'translateY(-3px)',
                        }
                    }}
                >
                    Version: v0.1
                </Typography>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    gutterBottom
                    sx={{
                        transition: 'color 0.4s, transform 0.4s',
                        '&:hover': {
                            color: '#757575',
                            transform: 'translateY(-3px)',
                        }
                    }}
                >
                    Build: Phoenix Release
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        transition: 'color 0.4s, transform 0.4s',
                        '&:hover': {
                            color: 'text.primary',
                            transform: 'translateY(-3px)',
                        }
                    }}
                >
                    This admin panel provides a secure and user-friendly interface to manage and monitor operations efficiently.
                    Designed for flexibility and scalability, it supports multiple users and roles, and can be easily integrated with other services.
                </Typography>
            </Box>
        </Paper>
    );
};

export default AboutPage;
