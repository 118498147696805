import React, { useState } from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box} from '@mui/material';
import { format } from 'date-fns';


const LogDialog = ({ open, log, onClose, sortOrder }) => {
    const [showMetadata, setShowMetadata] = useState(false);

    const sortedRelationalLogs = log.relational_logs.sort((a, b) => {
        if (sortOrder.order === 'asc') {
            return new Date(a.timestamp) - new Date(b.timestamp);
        } else {
            return new Date(b.timestamp) - new Date(a.timestamp);
        }
    });

    const handleMetadataToggle = () => {
        setShowMetadata(!showMetadata);
    };

    const valueStringify = (value) => {
        if (typeof value === 'object') {
            return JSON.stringify(value);
        } else {
            return value;
        }
    };


    const RelationalLogsHeading = ({heading, bodyText}) => (
        <Typography sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }} key={heading}>
            <Typography sx={{ fontWeight: 'bold', marginRight: '8px' }}>{heading}:</Typography>
            <Typography>{bodyText}</Typography>
        </Typography>
    );

    const MetadataDisplay = ({keyName, value}) => (
        <Typography key={keyName} sx={{ display: 'flex', marginBottom: '8px' }}>
            <Typography sx={{ fontWeight: 'bold', marginRight: '8px' }}>{keyName}:</Typography>
            <Typography sx={{ margin: 0, style: 'wrap', wordBreak: 'break-word' }}>{valueStringify(value)}</Typography>
        </Typography>
    );

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth sx={{ padding: '16px' }}>
            <DialogTitle sx={{ fontWeight: 'bold', fontSize: '1.5rem' }}
            >Relational Logs</DialogTitle>
            <DialogContent>
                <RelationalLogsHeading heading="EUID" bodyText={log.euid} />
                <RelationalLogsHeading heading="Subject" bodyText={log.subject} />
                <RelationalLogsHeading heading="From Email" bodyText={log.from_email_id} />
                <RelationalLogsHeading heading="Recipients" bodyText={log.recipients.join(', ')} />
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Button onClick={handleMetadataToggle}>
                        {showMetadata ? 'Hide Metadata' : 'Show Metadata'}
                    </Button>
                </Box>
                <TableContainer component={Paper} style={{ marginTop: '16px' }}>
                    <Table>
                        <TableHead sx={{ backgroundColor: '#f5f5f5' }}><TableRow>
                            <TableCell style={{ width: '20%' }}>Date & Time</TableCell>
                            <TableCell style={{ width: '20%' }}>Status Description</TableCell>
                            {showMetadata && <TableCell>Metadata</TableCell>}
                        </TableRow></TableHead>
                        <TableBody>
                            {sortedRelationalLogs.map((relLog, index) => (
                                <TableRow key={index}>
                                <TableCell>{format(new Date(relLog.timestamp), 'yyyy-MM-dd hh:mm:ss a')}</TableCell>
                                <TableCell>{relLog.status_description}</TableCell>
                                {showMetadata && (
                                    <TableCell>
                                        {Object.entries(relLog.meta_data).map(([key, value]) => (
                                            <MetadataDisplay keyName={key} value={value} />
                                        ))}
                                    </TableCell>
                                )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default LogDialog;
