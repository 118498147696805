import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Button, TableSortLabel, TablePagination } from '@mui/material';
import { format } from 'date-fns';

const LogTable = ({ logs, pagination, onRowClick, onSortChange, sortOrder, onPageChange }) => {
    const [page, setPage] = useState(pagination.page - 1); // Adjust for zero-based index

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
        pagination.page = newPage + 1; // Update to one-based index if needed
        // Assuming onPageChange updates the logs and pagination data
        onPageChange(event, newPage + 1);
    };


    const handleSortChange = (field) => {
        const isAsc = sortOrder.field === field && sortOrder.order === 'asc';
        onSortChange(field, isAsc ? 'desc' : 'asc');
    };


    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                        <TableSortLabel
                            active={sortOrder.field === 'timestamp'}
                            direction={sortOrder.order}
                            onClick={() => handleSortChange('timestamp')}
                        >
                            Date & Time
                        </TableSortLabel>
                        </TableCell>
                        <TableCell>
                        <TableSortLabel
                            active={sortOrder.field === 'from_email_id'}
                            direction={sortOrder.order}
                            onClick={() => handleSortChange('from_email_id')}
                        >
                            From Email
                        </TableSortLabel>
                        </TableCell>
                        <TableCell>
                        <TableSortLabel
                            active={sortOrder.field === 'subject'}
                            direction={sortOrder.order}
                            onClick={() => handleSortChange('subject')}
                        >
                            Subject
                        </TableSortLabel>
                        </TableCell>
                        <TableCell>
                        <TableSortLabel
                            active={sortOrder.field === 'status'}
                            direction={sortOrder.order}
                            onClick={() => handleSortChange('status')}
                        >
                            Email Status
                        </TableSortLabel>
                        </TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {logs.map((log) => (
                        <TableRow key={log.euid}>
                        <TableCell>{format(new Date(log.timestamp), 'yyyy-MM-dd hh:mm:ss a')}</TableCell>
                        <TableCell>{log.from_email_id.substring(0, 25)}</TableCell>
                        <TableCell>{log.subject.substring(0, 25)}</TableCell>
                        <TableCell>{log.status}</TableCell>
                        <TableCell>
                            <Button variant="contained" onClick={() => onRowClick(log)}>View Details</Button>
                        </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                component="div"
                count={pagination.total_count}
                page={page}
                onPageChange={handlePageChange}
                rowsPerPage={pagination.per_page}
                rowsPerPageOptions={[25]}
            />
        </>
    );
};

export default LogTable;
